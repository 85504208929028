@use "sass:math"

$colors: indigo, darkviolet, magenta, mediumvioletred, deeppink, crimson, red, orangered, darkorange, orange, darkgoldenrod, olive, olivedrab, limegreen, mediumseagreen, teal, lightseagreen, deepskyblue, dodgerblue, blue

.section-primary
  @extend .section
  background-color: $primary

.section-primary-light
  @extend .section
  background-color: $primary-light

.footer-no-padding
  @extend .footer
  background-color: transparent
  padding: 0
  margin-top: -86px

.div
  display: inherit
  &.is-vcentered
    position: relative
    top: 50%
    -ms-transform: translateY(-50%)
    transform: translateY(-50%)

  @for $i from 0 through 12
    &.is-#{$i}
      flex: none
      width: percentage(math.div($i, 12))
    &.is-offset-#{$i}
      margin-left: percentage(math.div($i, 12))

    +from($desktop)
      &.is-#{$i}-desktop
        flex: none
        width: percentage(math.div($i, 12))
      &.is-offset-#{$i}-desktop
        margin-left: percentage(math.div($i, 12))

.header-label
  font-family: "Nunito Sans"
  font-style: normal
  font-weight: 800
  font-size: 10px
  line-height: 16px
  display: flex
  align-items: center
  letter-spacing: 0.96px
  text-transform: uppercase
  color: #363636

  @for $i from 0 through 12
    &.label-size-#{$i}
      font-size: 10+$i*2px
      line-height: 16+$i*2px

  &.is-white
    color: #FFFFFF

.fineprint-label
  /* FINEPRINTS */
  font-family: "Nunito Sans"
  font-style: normal
  font-weight: normal
  font-size: 10px
  line-height: 10px

  /* identical to box height, or 100% */
  text-align: right
  letter-spacing: 0.1px

  /* PX GRAY */
  color: #363636

  @for $i from 0 through 12
    &.label-size-#{$i}
      font-size: 10+$i*2px
      line-height: 10+$i*2px

  &.is-left
    text-align: left

  &.is-white
    color: #FFFFFF

  &.is-light
    color: $primary-light

.button-label
  font-family: "Nunito Sans"
  font-style: normal
  font-weight: 600
  font-size: 14px
  line-height: 18px

  /* or 129% */
  display: flex
  align-items: center
  letter-spacing: 0.7px
  text-transform: uppercase

  &.is-grey
    color: #363636

  @for $i from 0 through 12
    &.label-size-#{$i}
      font-size: 10+$i*2px
      line-height: 10+$i*2px

.message-box
  @extend .message

  .message-header
    @extend .header-label
    color: #000
    background-color: #fff !important
    border-radius: 6px
    box-shadow: 0 0.5em 1em -0.125em rgba(#000, 0.1), 0 0px 0 1px rgba(#000, 0.02)
    margin-bottom: 2rem
    height: 55px
    cursor: pointer

  .message-body
    margin-top: -2rem

.hover-buttons
  &.is-right
    position: absolute
    right: 10px

.hover-button
  +unselectable
  -moz-appearance: none
  -webkit-appearance: none
  // background-color: bulmaRgba($scheme-invert, 0.1)

  cursor: pointer
  border: none
  border-radius: $radius-rounded
  outline: none
  position: relative
  vertical-align: top
  flex-grow: 0
  flex-shrink: 0

  &.is-right
    position: absolute !important
    right: 10px

  &.is-small
    height: 30px
    width: 30px
    max-height: 30px
    max-width: 30px
    min-height: 30px
    min-width: 30px

  &.is-large
    height: 60px
    width: 60px
    max-height: 60px
    max-width: 60px
    min-height: 60px
    min-width: 60px

.default-icon
  color: #fff
  padding: 2px
  display: flex
  justify-content: center
  align-items: center
  border-radius: 100%
  width: 40px
  height: 40px
  font-weight: 600
  font-size: larger

  &.is-medium
    width: 32px
    height: 32px
    font-size: medium

  @for $i from 1 through length($colors)
    &.bg-#{$i}
      background: lighten(nth($colors, $i), 20%)

@for $i from 1 through length($colors)
  .background-#{$i}
    background: nth($colors, $i)

.modal-footer
  position: fixed
  bottom: 3rem
  right: 4rem
  z-index: 41 // Modal z-index + 1

.portal
  position: absolute
  z-index: 100
  top: -9999px
  left: -9999px
  visibility: hidden

  &.is-box
    padding: 3px
    background: white
    border-radius: 4px
    box-shadow: 0 1px 5px rgba(0,0,0,0.2)

.content
  @extend .content
  &.no-block
    p
      margin-bottom: 0rem !important
  &.no-wrap
    display: block
    overflow: scroll
    white-space: nowrap

.spinner
  color: transparent !important
  pointer-events: none
  height: 3rem
  &::after
    @extend %loader
    +center(3em)
    position: relative !important
    height: 3em !important
    width: 3em !important

.slider
  -webkit-appearance: none
  width: 100%
  height: 10px
  border-radius: 2.5px
  background: #eee
  outline: none
  opacity: 0.9
  -webkit-transition: .2s
  transition: opacity .2s

  &:hover
    opacity: 1

  &::-webkit-slider-thumb,
  &::-moz-range-thumb
    -webkit-appearance: none
    appearance: none
    width: 20px
    height: 20px
    border-radius: 15%
    border-color: $primary
    background: $primary
    cursor: pointer

  &.is-disabled
    &::-moz-range-thumb,
    &::-webkit-slider-thumb
      border-color: #dbdbdb
      background: #dbdbdb

.page
  min-height: calc(100vh - #{$navbar-height})

  // padding top + font + (border padding + border) + padding bottom
  $page-title-height: calc(3rem + 2rem + 1rem + 2px + 1rem)

  .title
    height: $page-title-height
    padding: 3rem 1rem 1rem

    font-family: Nunito Sans
    font-style: normal
    font-weight: bold
    font-size: 24px
    line-height: 30px

    letter-spacing: 2px
    text-transform: uppercase

    color: #F8CD30

    .border
      padding-top: 1rem
      border-bottom: 2px solid #F8CD30

  .layout-settings
    padding: 0rem 2rem 1rem

  .main
    font-size: inherit
    min-height: calc(100vh - #{$navbar-height} - #{$page-title-height} - 12px)

.seperator
  border-bottom: 1px solid hsl(0deg, 0%, 93%)


.tile-is-white
  @extend .notification
  background-color: white

.updown
  animation: updown-animation 1.5s ease-in-out infinite

@keyframes updown-animation
  0%
    transform: translateY(0)
  50%
    transform: translateY(-1rem)
  0%
    transform: translateY(0)

.hide
  animation: hide-animation 1.5s linear 1
  animation-fill-mode: forwards

@keyframes  hide-animation
  0%
    opacity: 1.0
  100%
    opacity: 0.0

.is-rounded
  border-radius: $radius-rounded

