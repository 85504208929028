@import 'styles'

.section-fullheight
  @extend .section
  min-height: calc(100vh - 85px)
  @media only screen and (min-width: 768px)
    padding-top: 10vh

.preview
  background: #FFFFFF
  opacity: 0.5

  border: 1px solid #FFFFFF
  box-sizing: border-box

  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05), 0px 2px 5px rgba(0, 0, 0, 0.1)
