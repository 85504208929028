.chart-container
  position: absolute
  top: 0
  width: 80%
  height: 100%

.map-container
  position: absolute
  top: 0
  width: 80%
  height: 100%

.widget-control-container
  position: absolute
  height: 100%
  width: 20%
  left: 80%
  top: 0
  box-shadow: 0 0.5em 1em -0.125em rgba(#000, 0.1), 0 0px 0 1px rgba(#000, 0.02)
  overflow-y: auto

