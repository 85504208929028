@font-face
  font-family: 'Nunito'
  font-style: normal
  font-weight: 400
  font-display: swap
  src: local(''), url('../fonts/nunito-v16-latin-regular.woff2') format('woff2')

@font-face
  font-family: 'Nunito'
  font-style: normal
  font-weight: 700
  font-display: swap
  src: local(''), url('../fonts/nunito-v16-latin-700.woff2') format('woff2')

@font-face
  font-family: 'Nunito Sans'
  font-style: normal
  font-weight: 400
  font-display: swap
  src: local(''), url('../fonts/nunito-sans-v6-latin-regular.woff2') format('woff2')

@font-face
  font-family: 'Nunito Sans'
  font-style: normal
  font-weight: 600
  font-display: swap
  src: local(''), url('../fonts/nunito-sans-v6-latin-600.woff2') format('woff2')

@font-face
  font-family: 'Nunito Sans'
  font-style: normal
  font-weight: 800
  font-display: swap
  src: local(''), url('../fonts/nunito-sans-v6-latin-800.woff2') format('woff2')

