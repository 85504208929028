.draggable
  position: relative

  &.is-active
    cursor: move

.drag-content
  position: relative

.drag-content:after
  content: ""
  display: block
  position: absolute
  left: 0
  right: 0
  top: 0
  bottom: 0

