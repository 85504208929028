@import 'styles'

.card-block
  position: relative
  width: 60px
  height: 60px

  /* WHITE */
  background: #FFFFFF

  /* PX LG */
  border: 1px solid #EAEAEA
  box-sizing: border-box

  /* Button Shadow */
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25)
  border-radius: 2px

.card-icon
  position: absolute
  width: 20px
  height: 20px
  left: calc(50% - 20px/2)
  top: calc(50% - 20px/2 - 4px)

.card-link
  @extend .fineprint-label
  font-weight: 800

  position: absolute
  height: 13px
  left: 5%
  right: 5%
  top: 44px

  text-align: center
  letter-spacing: 0.1px

  /* PX GRAY */
  color: #363636
