@import 'styles'

.p-modal
  @extend .modal

.p-modal-card
  @extend .modal-card
  height: calc(100vh - #{$modal-card-spacing})

.p-modal-content,
.p-modal-card
  @extend .modal-content
  width: 95%

.p-modal-card-foot
  @extend .modal-card-foot
  justify-content: flex-end

.transformer-control-container
  position: absolute
  height: 100%
  width: 20%
  left: 40%
  top: 0
  box-shadow: 0 0.5em 1em -0.125em rgba(#000, 0.1), 0 0px 0 1px rgba(#000, 0.02)

.query-font
  font-family: "Nunito Sans"
  font-style: normal
  font-weight: 600
  font-size: 12px
  line-height: 18px
  display: flex
  align-items: center
  letter-spacing: 0.4px
  color: #363636

  @for $i from 0 through 12
    &.label-size-#{$i}
      font-size: 10+$i*2px
      line-height: 16+$i*2px

.control-body
  position: relative
  height: 100%

  .control-settings
    position: relative
    height: 90%
    overflow-y: auto

  .commit-footer
    position: relative
    height: 10%

    .button
      position: absolute
      bottom: 10px

