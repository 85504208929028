@import 'styles'

.panel-tab-header
  @extend .header-label
  margin-top: 5px
  margin-bottom: 5px

  &:not(.is-active)
    color: #EAEAEA

.panel-block-nb
  @extend .panel-block

  &:not(:last-child)
    border-bottom: none

  &.is-button
    cursor: pointer
    &:hover
      background-color: $background

.help-icon
  border-radius: $radius-rounded
  outline: none
  border: none
  cursor: pointer
