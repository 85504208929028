.dropdown-input
  min-width: 50px
  overflow: clip
  text-overflow: ellipsis
  border: none
  cursor: pointer

  &:focus
    outline: none

  &::placeholder
    color: black
    opacity: 1

  &::-ms-input-placeholder
    color: black
    opacity: 1

