@import 'styles'

.data-box
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25)
  border-radius: 2px
  width: 192px
  height: 96px

  &.is-loading
    color: transparent !important
    pointer-events: none
    &::after
      @extend %loader
      +center(1em)
      position: absolute !important

.data-header
  @extend .header-label

.bottom-elipse
  position: absolute
  left: 5px
  bottom: 1px

.bottom-date
  @extend .fineprint-label

  position: absolute
  right: 5px
  bottom: 7px


