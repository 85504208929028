@import 'styles'

.p-modal
  @extend .modal

.p-modal-card
  @extend .modal-card
  height: calc(100vh - #{$modal-card-spacing})

.p-modal-content,
.p-modal-card
  @extend .modal-content
  width: 95%

.p-modal-card-foot
  @extend .modal-card-foot
  justify-content: flex-end

.settings-container
  position: absolute
  height: 100%
  width: 25%
  left: 75%
  top: 0
  box-shadow: 0 0.5em 1em -0.125em rgba(#000, 0.1), 0 0px 0 1px rgba(#000, 0.02)

.settings-wrapper
  position: relative
  height: 100%

  .settings-body
    position: relative
    height: 90%
    overflow-y: auto

  .settings-footer
    position: relative
    height: 10%

    .button
      position: absolute
      bottom: 0px
      right: 0px

.share-card
  border-radius: 19px

  &:focus,
  &:focus-within,
  &:hover
    background-color: #f5f5f5

