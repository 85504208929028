@import 'styles'

.content-block
  @extend .block
  padding-bottom: 1.5rem
  border-bottom: 1px dashed #dbdbdb

  &:last-child
    border-bottom: none

