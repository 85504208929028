.bar-wrapper
  position: relative

.sidebar-section
  position: fixed
  height: 100%
  width: inherit
  z-index: 1
  top: 0
  left: 0
  overflow-x: hidden
  background: linear-gradient(265.73deg, #3457A6 -11.78%, #02184F 98.17%)
  padding-top: 3.5rem
  padding-left: 1.25rem

.minibar-section
  position: fixed
  height: 100%
  width: inherit
  z-index: 1
  top: 0
  left: 0
  overflow-x: hidden
  background: linear-gradient(265.73deg, #3457A6 -11.78%, #02184F 98.17%)
  padding-top: 3.5rem

.main-section
  position: inherit

.bar-footer
  position: fixed
  display: block
  width: inherit
  bottom: 1rem
  padding-right: 1.25rem

