@import 'styles'

.menu-container
  position: absolute
  z-index: 999

.avatar
  margin: 5
  cursor: pointer
  z-index: 9999 !important
  border-radius: $radius-rounded

  &.is-menu
    position: absolute
    margin: 0
    right: 5px

.avatar-box
  @extend .box
  padding: 0
  position: relative
  padding: 5px
  min-width: 225px
  min-height: 200px

.avatar-title
  @extend .media-content
  position: relative
  margin-right: 50px
  padding: 10px
  white-space: nowrap
  display: block

.avatar-menu-list
  @extend .menu-list

  a
    &:hover
      background-color: hsl(0, 0%, 96%)
      color: hsl(0, 0%, 21%)

