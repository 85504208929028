@use "sass:math"

$picker-button-width: 100px
$picker-container-width: 180px


.picker-button
  color: white
  border-width: 0px
  height: 40px
  width: $picker-button-width

.picker-container
  position: absolute
  top: 50px

  &.is-center
    left: math.div($picker-button-width, 2) - math.div($picker-container-width,2)

.picker-box
  width: $picker-container-width
  position: relative
  background: white
  box-shadow: rgba(#000, 0.1) 0px 3px 12px
  color: white
  z-index: 10

  .picker-arrow
    width: 0px
    height: 0px
    border-style: solid
    border-width: 0px 10px 10px
    position: absolute
    top: -10px
    left: 50%
    border-top-color: transparent
    border-right-color: transparent
    border-left-color: transparent
    margin-left: -(math.div($picker-container-width, 2) - math.div($picker-button-width, 2)) - 10px

    &.is-center
      margin-left: -10px

  .picker-preview
    height: 120px
    border-radius: 5px 5px 0px 0px
    display: flex
    align-items: center
    justify-content: center

  .picker-content
    height: 120px
    border-radius: 0px 0px 5px 5px
    background-color: white
    padding-left: 5px

    .picker-colors
      display: flex
      flex-wrap: wrap
      align-content: flex-start
      padding: 10px

      .picker-color
        height: 20px
        width: 20px
        border-radius: 3px
        margin: 5px
        flex: none
        cursor: pointer

